.loadingApp.bumps {
    margin-top: 150px;
}


.searchApp {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    gap: 15px;
    flex-wrap: wrap;
}


.searchBar {
    background: rgba(255, 255, 255, 0.05);
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
}

.searchBar input {
    width: 100%;
    padding: 5px 20px;
    border: none;
    outline: none;
    background: none;
}

.searchBar .srcBtn {
    background: var(--w);
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
    border-radius: 9px;
    cursor: pointer;
}


.searchBar .srcBtn svg {
    fill: var(--g1);
}



.sortMenu {
    position: relative;
    width: 220px;
}

.sortMenu .label {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    user-select: none;
}

.sortMenu .label .txt {
    display: flex;
    gap: 15px;
    align-items: center;
    font-size: 15px;
}

.sortMenu .label .btn {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: var(--w1);
    transition: ease 0.4s;
}


.sortMenu.open .label .btn {
    transform: rotate(-180deg);
}

.sortMenu .drop {
    position: absolute;
    background: rgb(19, 19, 19);
    width: 100%;
    top: 55px;
    border-radius: 11px;
    padding: 10px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
    z-index: 99;
}

.sortMenu.open .drop {
    opacity: 1;
    pointer-events: all;
}


.sortMenu .drop ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.sortMenu .drop ul li {
    border-radius: 9px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px 10px;
    text-wrap: nowrap;
    cursor: pointer;
    transition: ease 0.4s;
    font-size: 15px;
}

.sortMenu .drop ul li.active {
    background: rgba(255, 255, 255, 0.05);
}
.sortMenu .drop ul li:hover {
    background: rgba(255, 255, 255, 0.03);
}


.badges {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
}

.badges h1 {
    font-size: 30px;
    font-weight: 900;
}

.badgesList {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}


.badgesList .b {
    display: flex;
    align-items: center;
    gap: 5px;
    background: rgb(19, 19, 19);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 3px 10px;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.badgesList .b.selected {
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
}

.badgesList .b div {
    display: flex;
    align-items: center;
}

.badgesList .b .hided {
    opacity: 0;
}



.badgesList .b.selected .lbl {
    transform: translateX(-60%);
}

.badgesList .b .lbl {
    font-size: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: max-content;
    transition: ease 0.2s;
}


.badgesList .b.selected .icon {
    opacity: 1;
}

.badgesList .b .icon {
    font-size: 18px;
    opacity: 0;
}




.serversList {
    padding: 70px 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
    gap: 50px;
    flex-wrap: wrap;
    width: 100%;
}


.server {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px 30px;
    width: 100%;
    text-decoration: none;
}


.server .srvLink {
    text-decoration: none;
}


.server .top {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
}


.server .top .txt {
    width: calc(100% - 50px - 30px);
    display: flex;
    flex-direction: column;
}

.server .top .txt .name {
    font-size: 18px;
    font-weight: 800;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.server .top .txt .bList {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    gap: 9px;
    min-height: 26px;
    min-width: 30px;
}

.server .top .txt .bList .bls {
    position: relative;
    height: 20px;
    cursor: pointer;
}

.server .top .txt .bList .bls .drop {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    font-size: 14px;
    background: var(--primary);
    padding: 3px 10px;
    z-index: 99;
    border-radius: 7px;
    transition: ease 0.2s;
    color: var(--back);
    font-weight: 700;
    width: max-content;
}

.server .top .txt .bList .bls .drop::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary) transparent;
  }

.server .top .txt .bList .bls:hover .drop {
    opacity: 1;
    pointer-events: all;
}

.server .top .txt .bList img {
    height: 100%;
}

.server .top .txt .bumps {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.9;
}

.server .top .txt .bgmp {
    display: flex;
    align-items: center;
    gap: 10px;
}

.server .top .txt .starRating {
    display: flex;
    align-items: center;
    font-size: 22px;
}

.server .top .txt .starRating svg {
    fill: var(--primary);
}

.server .top .txt .starRating svg.empty {
    fill: rgba(255, 255, 255, 0.1);
}

.server .icon {
    width: 50px;
    height: 50px;
    background: var(--back);
    border-radius: 35%;
}

.server .icon img {
    width: 100%;
    border-radius: 35%;
    height: 100%;
}



.server .middle .cats {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-top: 20px;
}

.server .middle .cats .mCt {
    padding: 6px 17px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    opacity: 0.9;
    text-align: center;
}

.server .middle .description {
    font-size: 16px;
    font-weight: 500;
    color: var(--w1);
    opacity: 0.8;
    padding: 20px 10px;
}

.server .bottom {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: flex-end;;
    height: 100%;
    flex-direction: column;
    gap: 10px;
}


.server .bottom .btn {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: var(--w);
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
    padding: 5px 20px;
    border-radius: 15px;
    width: 100%;
    text-align: center;
}

.server .bottom .btn:last-child {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
}


.server .bottom .join.disabled {
    cursor: not-allowed;
}




 @media screen and (max-width: 500px) {
    .badges {
        padding-left: 25px;
        padding-right: 25px;
    }
    .serversList {
        padding: 60px 25px;
    }
    .searchApp {
        padding-top: 80px;
    }
 }