.navbarDashApp {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 70px;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
}


  .navbarDashApp .sd {
    display: flex;
    align-items: center;
  }


  .navbarDashApp .cnt {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbarDashApp .cnt a {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
  }

  .navbarDashApp .cnt h1 {
    font-size: 20px;
    font-weight: 900;
  }

  .navbarDashApp .cnt img:not(.avatar) {
    height: 50px;
  }
  

  .navbarDashApp .resBtn {
    font-size: 20px;
    color: var(--w);
    display: none;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {
  .navbarDashApp .resBtn {
    display: flex;
  }
  }


  .navbarDashApp ul.links {
    display: flex;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px 50px;
  }

  .navbarDashApp li a {
    text-decoration: none;
    opacity: 0.8;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    width: max-content;
    align-items: center;
  }

  .navbarDashApp ul.links li {
    position: relative;
  }


.navbarDashApp ul.links li:not(.login)::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 3px;
    background: var(--w);
    opacity: 0.1;
    border-radius: 100px;
    bottom: -5px;
    left: 0px;
    transition: ease 0.4s;
  }

  .navbarDashApp ul.links li.active::before {
    width: 40%;
    opacity: 1;
    background: var(--primary);
  }

  .navbarDashApp ul.links li:hover:before {
    width: 40%;
  }

  .navbarDashApp ul.links li.active:hover:before {
    width: 40%;
  }

  

  
  .navbarDashApp li.login {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    border-radius: 12px;
    padding:  8px 17px;
    list-style: none;
  }

  .navbarDashApp li.login a {
    color: var(--w1);
    gap: 10px;
    font-weight: 700;
  }

  .navbarDashApp .userDrop li.login.connected a {
    text-transform:none;
    color: var(--w1);
    opacity: 1;
    padding: 4px 15px 4px 8px;
    font-weight: 600;
  }

  .navbarDashApp .userDrop li.login.connected {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 0px;
  }

  .navbarDashApp .userDrop li.login img {
    width: 30px;
    height: 30px;
    border-radius: 35%;
  }

  .navbarDashApp .userDrop li.login svg {
    font-size: 20px;
    margin-bottom: -1px;
    transition: ease 0.3s;
  }

  
@media screen and (max-width: 700px) { 
    .navbarDashApp .userDrop li.login span {
        display: none;
    }
}


  .navbarDashApp .userDrop li.login.open svg {
    transform: rotate(-180deg);
    transition: ease 0.3s;
  }


  .navbarDashApp .userDrop {
    position: relative;
  }
  .navbarDashApp .userDrop .drop {
    position: absolute;
    background: #161616;
    padding: 10px 10px;
    border-radius: 12px;
    width: 300px;
    right: 0px;
    top: calc(100% + 10px);
    transition: ease 0.2s;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  .navbarDashApp .userDrop.open .drop {
    opacity: 1;
    pointer-events: all;
  }

  .navbarDashApp .userDrop .drop ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbarDashApp .userDrop .drop ul .sep {
    width: 90%;
    height: 1.5px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 100px;
    margin: 10px 0px;
  }

  .navbarDashApp .userDrop .drop ul a {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
    padding: 6px 14px;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    transition: ease 0.2s;
  }

  .navbarDashApp .userDrop .drop ul a.active {
    background: rgba(255, 255, 255, 0.06);
  }

  .navbarDashApp .userDrop .drop ul a:not(.active):hover {
    background: rgba(255, 255, 255, 0.03);
  }