.channelsConfig {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.channelsConfig .selectChannel {
    position: relative;
    max-width: 350px;
    margin-top: 10px;
    width: 100%;
}

.channelsConfig .selectChannel .label {
    display: flex;
    align-items: center;
    background:rgb(19, 19, 19);
    border-radius: 13px;
    padding: 5px 20px;
    width: 100%;
    height: 45px;
    gap: 6px;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
}

.channelsConfig .selectChannel .label svg {
    font-size: 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
    transition: ease 0.4s;
}

.channelsConfig .selectChannel .label.open svg {
    transform: rotate(180deg);
}

.channelsConfig .drp {
    position: absolute;
    top: calc(100% + 13px);
    background:rgb(19, 19, 19);
    padding: 10px 10px;
    border-radius: 10px;
    width: calc(100%);
    left: 0px;
    max-height: 230px;
    min-height: 60px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
    z-index: 99;
    user-select: none;
}
.channelsConfig .drp.open {
    opacity: 1;
    pointer-events: all;
}

.channelsConfig .drp .drpElList {
    overflow-x: auto;
    height: 100%;
    max-height: calc(230px - 30px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 10px;
}

.channelsConfig .drp .drpElList::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}


.channelsConfig .drp .drpElList::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 100px;
}



.channelsConfig .drp .drpElList .drpop {
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    transition: ease 0.4s;
    cursor: pointer;
}

.channelsConfig .drp .drpElList .drpop:hover {
    background: rgba(255, 255, 255, 0.03);
}