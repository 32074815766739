@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

:root {
    --g1: #2E2E2E;
    --g2: #282828;
	--g3: #1f1f1f;
	--g4: #242424;
    --back: #0f0f0f;
    --primary: #a841ff;
    --seconde: rgb(115, 0, 182);
    --w: #c9c9c9;
    --w2: #daddda;
}


html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: linear-gradient(150deg, #0f0f0f 0%, #000000 100%);
    font-family: "Poppins", sans-serif;
}

body {
    scrollbar-gutter: stable;
}


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
    color: var(--w);
    font-family: "Poppins", sans-serif;
    -webkit-tap-highlight-color: transparent;
}

img {
    user-select: none;
}

.mainApp, #mainApp {
    width: 100%;
    height: 100%;
}







.flex {
    display: flex;
}
.flexCol {
    flex-direction: column;
}

.flexRow {
    flex-direction: row;
}

.center {
    justify-content: center;
}

.alignCenter {
    align-items: center;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mainContentLoad.loaded {
    opacity: 1;
    animation: fade 0.3s ease;
}
.mainContentLoad {
    opacity: 0;
}

@keyframes spinLoadAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    
}
.loadingApp {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: var(--primary);
}
.loadingApp svg {
    animation: spinLoadAnimation 1.5s ease infinite;
}



iframe#webpack-dev-server-client-overlay{display:none!important}


.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .loaderIcon {
    animation: spin 1s infinite ease;
    animation-delay: 0s;
    color: var(--primary);
    font-size: 30px;
  }
  
  
  
  
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    };
  }
  
  


  .needToLogin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 55vh;
  }

  .needToLogin .txts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .needToLogin .txts h1 {
    font-size: 35px;
    font-weight: 900;
    color: var(--w2);
    max-width: 700px;
    text-align: center;
  }

  .needToLogin .txts .btn {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 6px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
  }
  .needToLogin .txts .btn svg {
    font-size: 23px;
  }