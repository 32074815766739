

  /* Navbar */

  .navbarApp {
    display: flex;
    align-items: center;
    padding: 30px 0px;
    width: 100%;
  }

  .navbarApp .cnt, .navbarApp .sd {
    flex-grow: 1;
    text-align: center; 
    width: 100%;
  }

  .navbarApp .cnt {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbarApp .cnt h1 {
    font-size: 20px;
    font-weight: 900;
  }

  .navbarApp .cnt img:not(.avatar) {
    height: 50px;
  }

  .navbarApp .cnt .resBtn {
    font-size: 25px;
    color: var(--w);
    cursor: pointer;
    display: none;
  }


  .navbarApp ul.links {
    display: flex;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px 50px;
  }

  .navbarApp li a {
    text-decoration: none;
    opacity: 0.8;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    width: max-content;
    align-items: center;
  }

  .navbarApp ul.links li {
    position: relative;
  }

  .navbarApp ul.links li:not(.login)::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 3px;
    background: var(--w);
    opacity: 0.1;
    border-radius: 100px;
    bottom: -5px;
    left: 0px;
    transition: ease 0.4s;
  }

  .navbarApp ul.links li.active::before {
    width: 40%;
    opacity: 1;
    background: var(--primary);
  }

  .navbarApp ul.links li:hover:before {
    width: 40%;
  }

  .navbarApp ul.links li.active:hover:before {
    width: 40%;
  }

  
  .navbarApp li.login {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    border-radius: 12px;
    padding:  8px 17px;
    list-style: none;
  }

  .navbarApp li.login a {
    color: var(--w1);
    gap: 10px;
    font-weight: 700;
  }

  .navbarApp .userDrop li.login.connected a {
    text-transform:none;
    color: var(--w1);
    opacity: 1;
    padding: 4px 15px 4px 8px;
    font-weight: 600;
  }

  .navbarApp .userDrop li.login.connected {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 0px;
  }

  .navbarApp .userDrop li.login img {
    width: 30px;
    height: 30px;
    border-radius: 35%;
  }

  .navbarApp .userDrop li.login svg {
    font-size: 20px;
    margin-bottom: -1px;
    transition: ease 0.3s;
  }

  .navbarApp .userDrop li.login.open svg {
    transform: rotate(-180deg);
    transition: ease 0.3s;
  }


  .navbarApp .userDrop {
    position: relative;
  }
  .navbarApp .userDrop .drop {
    position: absolute;
    background: #161616;
    padding: 10px 10px;
    border-radius: 12px;
    width: 300px;
    right: 0px;
    top: calc(100% + 10px);
    transition: ease 0.2s;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  .navbarApp .userDrop.open .drop {
    opacity: 1;
    pointer-events: all;
  }

  .navbarApp .userDrop .drop ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbarApp .userDrop .drop ul .sep {
    width: 90%;
    height: 1.5px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 100px;
    margin: 10px 0px;
  }

  .navbarApp .userDrop .drop ul a {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
    padding: 6px 14px;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    transition: ease 0.2s;
  }

  .navbarApp .userDrop .drop ul a.active {
    background: rgba(255, 255, 255, 0.06);
  }

  .navbarApp .userDrop .drop ul a:not(.active):hover {
    background: rgba(255, 255, 255, 0.03);
  }

  .navbarApp .userDrop.atp {
    display: none;
  }


  @media screen and (max-width: 800px) {
    .navbarApp .userDrop li.login span {
      display: none;
    }
    .navbarApp .userDrop .drop {
      right: 0px;
      width: 250px;
    }
    .navbarApp {
      flex-direction: column;
      gap: 10px;
    }
    .navbarApp:not(.open) .sd {
      display: none;
    }
    .navbarApp .sd ul.links {
      padding-left: 25px;
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }
    .navbarApp .cnt {
      order: -1;
      width: 100%;
      padding: 0px 25px;
      position: relative;
      height: 50px;
    }
    .navbarApp .cnt .resBtn {
      display: block;
    }
    .navbarApp .cnt img:not(.avatar) {
      position: absolute;
      left: 50%;
      height: 40px;
      transform: translateX(-50%);
    }
  }

  
