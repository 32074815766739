.codeAppMain {
    padding: 0px 40px;
    padding-bottom: 100px;
}

.topInfs {
    display: flex;
    justify-content: space-between;
    padding-top: 90px;
    gap: 50px;
}

.topInfs .nameAndViews .a {
    display: flex;
    align-items: center;
    gap: 15px;
}

.topInfs .nameAndViews {
    display: flex;
    justify-content: space-between;
}

.topInfs .nameAndViews .b a {
    font-size: 15px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 13px;
    padding: 7px 23px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: max-content;
    color: rgba(255, 255, 255, 0.6);
}


.topInfs .nameAndViews .b a svg {
    display: flex;
    align-items: center;
    fill: rgba(255, 255, 255, 0.6);
}

.topInfs .lft {
    max-width: calc(100% - 350px);
    min-width: 350px;
} 

.topInfs .lft h1 {
    font-size: 25px;
    font-weight: 900;
    color: var(--primary);
}


.topInfs .lft h1::first-letter {
    text-transform: uppercase;
}
.topInfs .lft h3 {
    display: flex;
    align-items: center;
    font-weight: 900;
    gap: 8px;
    font-size: 17px;
    background: rgba(255, 255, 255, 0.01);
    border-radius: 13px;
    padding: 7px 23px;
}


.topInfs .lft p {
    line-break: anywhere;
    font-size: 15px;
    opacity: 0.8;
    letter-spacing: .5px;
    padding: 10px 5px;
}

.topInfs .rgt .image {
    background: rgba(255, 255, 255, 0.01);
    border-radius: 12px;
    width: 300px;
    min-height: 140px;
}

@media screen and (max-width: 800px) {
    .topInfs {
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }
    .topInfs .lft {
        max-width: 100%;
        width: 100%;
    }
}


.codeWrp {
    position: relative;
}

.passwordUnlock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    padding: 7px 7px;
    padding-left: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.passwordUnlock input {
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 5px;
    color: rgba(255, 255, 255, 0.8);
}

.passwordUnlock .verifyPsw {
    background: var(--primary);
    border-radius: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    cursor: pointer;
}
.passwordUnlock .verifyPsw svg {
    fill: rgba(0, 0, 0, 0.6);
}

.codeEditor {
    padding: 30px;
    padding-left: 0px;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    white-space: pre-wrap; /* Préserve les espaces blancs et les retours à la ligne */
    /* Autres styles pour l'éditeur... */
}

.codeEditor.locked {
    filter: blur(7px) saturate(0%);
    user-select: none;

}

.codeEditor * {
    font-family: "Source Code Pro", monospace !important;
}

.codeEditor .line {
    display: flex;
    gap: 15px;
}
.codeEditor .line .lineNumber {
    width: 60px;
    text-align: end;
}
.codeEditor .line .lineContent {
    line-break: anywhere;
    max-width: calc(100% - 120px);
}

.codeEditor .line .lineNumberSklt {
    width: 30px;
    text-align: end;
    margin-left: 30px;
    height: 20px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 6px;
}

.codeEditor .line .lineContentSklt {
    width: 30px;
    text-align: end;
    margin-left: 30px;
    height: 20px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 6px;
}

.cd-text {
    color: #9ae469;
}
.cd-function {
    color: #74fbff;
}
.cd-bracket {
    color: #ff4e4f;
}
.cd-semicolon {
    color: #ff4e4f;
}
.cd-nomention {
    color: #ff76f8;
}