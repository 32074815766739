.status h1 {
    font-size: 25px;
    color: var(--w2);
    font-weight: 900;
    opacity: 0.8;
}

.status h3 {
    font-size: 30px;
    color: var(--primary);
    font-weight: 900;
    margin-top: -15px;
}


.status .ask {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);    
    padding: 5px 20px;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: min-content;
    margin-top: 5px;
}

.status .ask.disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}

.settingsList.disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}


.settingsList {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
}

h1.tltSet {
    font-size: 22px;
    font-weight: 800;
    color: var(--w2);
}


textarea.txtSet {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    width: 90%;
    padding: 20px;
    resize: none;
    height: 120px;
    border: none;
    outline: none;
}

@media screen and (max-width: 500px) {
    textarea.txtSet {
        width: 100%;
    }
}


.saveBtnSet {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);    
    padding: 5px 20px;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: min-content;
    margin-top: 5px;
    transition: ease 0.3s;
}

.saveBtnSet.disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.7;
}

.saveBtnSet svg.ldgSet {
    animation: spin 1s ease infinite;
}





.badges {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
}

.badges h1 {
    font-size: 30px;
    font-weight: 900;
}

.catList {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}


.catList .ctSet {
    display: flex;
    align-items: center;
    gap: 5px;
    background: rgb(19, 19, 19);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 3px 10px;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.catList .ctSet.selected {
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
}

.catList .ctSet div {
    display: flex;
    align-items: center;
}

.catList .ctSet .hided {
    opacity: 0;
}



.catList .ctSet.selected .lbl {
    transform: translateX(-60%);
}

.catList .ctSet .lbl {
    font-size: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: max-content;
    transition: ease 0.2s;
}


.catList .ctSet.selected .icon {
    opacity: 1;
}

.catList .ctSet .icon {
    font-size: 18px;
    opacity: 0;
}


.saveBtnSet.cts {
    margin-top: 13px;
}




.rlList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 15px;
    padding: 20px 20px;
    margin-top: 20px;
}

.rlListFlex {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
}

.rlSetView {
    display: flex; 
    align-items: center;
    height: 50px;
    gap: 10px;
}


.rlSetView .dltRlset {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    background: rgba(255, 255, 255, 0.02);
    height: 50px;
    font-size: 18px;
    width: 60px;
    cursor: pointer;
    margin-left: auto;
}

.rlSetView .dltRlset svg {
    fill: rgba(255, 255, 255, 0.7);
}

.rlset {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 13px;
    padding: 5px 6px;
    width: 100%;
    height: 50px;
    gap: 6px;
}


.rlset input {
    padding: 5px 10px;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
}

.rlset input.bpc {
    width: 70px;
}


.rlset .rlView {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;
    width: 100%;
    position: relative;
}

.rlset .rlView .label {
    height: 100%;
    width: 100%;
}
.rlset .rlView .label input {
    padding: 0px 15px;
}



.rlset .rlView .drp {
    position: absolute;
    top: calc(100% + 13px);
    background:rgb(19, 19, 19);
    padding: 10px 15px;
    border-radius: 10px;
    width: calc(100% + 15px + 40px);
    right: 0px;
    max-height: 230px;
    min-height: 60px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s ease;
    z-index: 99;
}
.rlset .rlView .drp.open {
    opacity: 1;
    pointer-events: all;
}

.rlset .rlView .drp .drpElList {
    overflow-x: auto;
    height: 100%;
    max-height: calc(230px - 30px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 10px;
}

.rlset .rlView .drp .drpElList::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}


.rlset .rlView .drp .drpElList::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 100px;
}



.rlset .rlView .drp .drpElList .drpop {
    padding: 5px 20px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    transition: ease 0.4s;
    cursor: pointer;
}

.rlset .rlView .drp .drpElList .drpop:hover {
    background: rgba(255, 255, 255, 0.03);
}


.rlset .labelRlVw {
    padding: 5px 20px;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 15px;
}

.rlset .labelRlVw svg {
    font-size: 20px;
}

.rlset .labelRlVw .dlBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.rlList .addAnt {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);    
    padding: 5px 30px;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: min-content;
    transition: ease 0.3s;
    user-select: none;
}


@media screen and (max-width: 550px) {
    .rlSetView {
        flex-wrap: wrap;
        height: max-content;
    }
    .rlList {
        padding: 20px 0px;
        background: none;
    }
    .rlset .labelRlVw {
        gap: 10px;
    }
}