.loadingApp.bumps {
    margin-top: 150px;
}


.searchApp {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    gap: 15px;
    flex-wrap: wrap;
}


.searchBar {
    background: rgba(255, 255, 255, 0.05);
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
}

.searchBar input {
    width: 100%;
    padding: 5px 20px;
    border: none;
    outline: none;
    background: none;
}

.searchBar .srcBtn {
    background: var(--w);
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
    border-radius: 9px;
    cursor: pointer;
}


.searchBar .srcBtn svg {
    fill: var(--g1);
}





.prtsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 70px 0px; 
    gap: 50px 0px;
}

.prtsList .prtMapped {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 80%;
}

.prtMapped .icon {
    background: rgba(255, 255, 255, 0.03);
    width: 90px;
    height: 90px;
    border-radius: 35%;
}

.prtMapped .infs {
    display: flex;
    flex-direction: column;
}

.prtMapped .infs h1 {
    font-size: 25px;
    font-weight: 900;
}


.prtMapped .infs p {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .6px;
    opacity: .9;
}

.prtMapped .btns {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.prtMapped .btns a {
    font-size: 15px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 13px;
    padding: 7px 23px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: max-content;
    color: rgba(255, 255, 255, 0.6);
}
.prtMapped .btns a svg {
    display: flex;
    align-items: center;
    fill: rgba(255, 255, 255, 0.6);
}


@media screen and (max-width: 830px) {
    .prtsList .prtMapped {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .prtsList .prtMapped {
        width: calc(100% - 40px);
        flex-direction: column;
    }
    
.prtMapped .infs h1 {
    font-size: 23px;
}


.prtMapped .infs p {
    font-size: 15px;
}

}