.statsCounter {
    display: flex;
    align-items: center;
    gap: 10px 50px;
    flex-wrap: wrap;
}

.topBumpers {
    margin-top: 50px;
}

.topBumpers h1 {
    font-size: 30px;
    font-weight: 900;
}

.bpmrList {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.bpmrList .bpmr {
    background: rgba(255, 255, 255, 0.03);
    width: 100%;
    max-width: 600px;
    padding: 7px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}


.bpmrList .bpmr .avatar {
    width: 50px;
    height: 50px;
    border-radius: 35%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);
}

.bpmrList .bpmr .avatar img {
    width: 101%;
    height: 101%;
}

.bpmrList .bpmr .tp {
    font-size: 25px;
    font-weight: 900;
}

.bpmrList .bpmr .name {
    font-size: 22px;
    font-weight: 900;
    opacity: 0.8;
}

.bpmrList .bpmr .bmpsccc {
    margin-left: auto;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 800;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap: 6px;
}


.bpmrList .bpmr .bmpsccc span {
    color: var(--primary);
    font-size: 25px;
    font-weight: 900;
}

@media screen and (max-width: 500px) {
    .bpmrList .bpmr .bmpsccc b {
        display: none;
    }
}