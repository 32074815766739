.header {
    min-height: max-content;
    width: 100%;
    position: relative;
    padding-top: 100px;
}

.header .ctnt {
    display: flex;
    justify-content: center;
    padding: 0px 25px;
    padding-left: 70px;
}



.header .ctnt .txts {
    display: flex;
    flex-direction: column;
    position: relative;
}

.header .ctnt .txts svg.a {
    fill: var(--primary);
    width: 50px;
    position: absolute;
    left: -60px;
    top: -20px;
}


.header .ctnt .txts svg.b {
    fill: var(--w);
    width: 50px;
    position: absolute;
    right: -60px;
    bottom: -20px;
    opacity: 0.5;
}

.header .ctnt .txts h3 {
    color: var(--w);
    opacity: 0.5;
    font-size: 15px;
    font-weight: 400;
}

.header .ctnt .txts h3 a {
    text-decoration: none;
    padding: 4px 10px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    font-weight: 600;
}

.header .ctnt .txts h1 {
    font-size: 50px;
    font-weight: 900;
    color: var(--primary);
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.05));
    width: max-content;
}


.header .ctnt .txts h2 {
    font-size: 30px;
    font-weight: 900;
    color: var(--w);

}



.header .hdBtns {
    display: flex;
    align-items: center;
    gap: 15px 25px;
    margin-top: 15px;
    position: relative;
    width: max-content;
}

.header .hdBtns .hdb {
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
    border-radius: 12px;
    padding: 6px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    text-wrap: nowrap;
    cursor: pointer;
}


.header .hdBtns .hdb a {
    color: var(--back);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.header .hdBtns .hdb:last-child {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
}

.header .hdBtns .hdb:last-child a {
    color: var(--w2);
    opacity: 0.9;
}

@media screen and (max-width: 650px) {
    .header {
        padding-top: 80px;
    }
    .header .ctnt .txts h1 {
        font-size: 45px;
    }
    .header .ctnt { 
    padding-left: 25px;
    }
    .header .ctnt .txts svg {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .header .hdBtns {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .header .hdBtns .hdb {
        width: max-content;
        flex: 1;
        max-width: 180px;
    }
}








.linksContainer {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    gap: 15px 100px;
    justify-content: center;
    margin-top: 100px;
    flex-wrap: wrap;
}

.linkct {
    font-size: 60px;
    color: rgba(255, 255, 255, 0.06);
}

.linkct svg {
    fill: rgba(255, 255, 255, 0.3);
    height: 40px;
}


.features {
    display: flex;
    align-items: start;
    width: 100%;
    justify-content: space-around;
    margin-top: 150px;
    gap: 40px;
    flex-wrap: wrap;
}

.feature {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.feature .icon {
    font-size: 50px;
}

.feature .icon svg {
    fill: var(--primary);
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
}

.feature h3 {
    max-width: 300px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    opacity: 0.8;
}





.botStats {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px auto;
    gap: 15px 100px;
    justify-content: center;
    margin-top: 200px;
    flex-wrap: wrap;
}


.botStats .sts {
    display: flex;
    flex-direction: column;
}


.botStats .sts h1 {
    font-size: 50px;
    font-weight: 900;
    color: var(--primary);
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
    display: flex;
    align-items: center;
}

.botStats .sts h3 {
    font-size: 27px;
    font-weight: 900;
    opacity: 0.7;
    margin-top: -20px;
    display: flex;
    align-items: center;
}

.botStats .sts h1 span {
    font-size: 40px !important;
    color: var(--primary);
}

@media screen and (max-width: 600px) {
    .linksContainer {
        gap: 15px 70px;
    }
    .botStats { 
        flex-direction: column;
        gap: 50px;
        padding: 0px 50px;
    }
    .botStats .sts { 
        width: 100%;
    }
}



.footerApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 250px;
    padding-bottom: 50px;
}


.footerApp .scrollToTop {
    font-size: 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 15px 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

.footerApp .scrollToTop svg {
    fill: rgba(255, 255, 255, 0.7)
}


.footerApp .cprt {
    font-size: 30px;
    font-weight: 900;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0px 25px;
    text-align: center;
}



body::-webkit-scrollbar-thumb {
    background: transparent;
}

body::-webkit-scrollbar {
    width: 0px;
}
@media screen and (min-width: 600px) {
body::-webkit-scrollbar-thumb {
    background: var(--primary);
}

body::-webkit-scrollbar {
    width: 5px;
}
}




.imagesSlide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.imagesSlide .prevArr, .nextArr {
    display: flex;
    max-width: 100px;
    width: 100%;
    justify-content: flex-end;
    position: relative;
    z-index: 99;
}

.pvBtnIc {
    font-size: 15px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 7px 10px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
}


.nextArr {
    justify-content: flex-start !important;
}




.imagesSlide .sld {
    display: flex;
    align-items: center;
    flex: 1;
    width: calc(100% - 300px);
    flex-wrap: nowrap;
    gap: 40px;
    justify-content: center;
    position: relative;
    z-index: 2;
}




.imagesSlide .sld .sldImg {
    width: 350px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    height: calc(9 / 16 * 350px);
    flex-shrink: 0;
    overflow: hidden;
}

.imagesSlide .sld .sldImg img {
    width: 101%;
    height: 101%;
    object-fit: contain;
}



@media screen and (max-width: 1000px) {
    .imagesSlide .prevArr, .nextArr { 
        width: 60px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 850px) {
    .imagesSlide .prevArr, .nextArr { 
        width: 100px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 500px) {
    .sldImg {
        width: 300px !important;
        height: calc(9 / 16 * 300px) !important;
    }
    .imagesSlide .prevArr, .nextArr { 
        width: 70px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 420px) {
    .sldImg {
        width: 250px !important;
        height: calc(9 / 16 * 250px) !important;
    }
    .imagesSlide .prevArr, .nextArr { 
        width: 50px;
        justify-content: center !important;
    }
}