.loadingApp.commands {
    padding-top: 100px;
}


.searchApp {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    gap: 15px;
    flex-wrap: wrap;
}


.searchBar {
    background: rgba(255, 255, 255, 0.05);
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
}

.searchBar input {
    width: 100%;
    padding: 5px 20px;
    border: none;
    outline: none;
    background: none;
}

.searchBar .srcBtn {
    background: var(--w);
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
    border-radius: 9px;
    cursor: pointer;
}


.searchBar .srcBtn svg {
    fill: var(--g1);
}



.sortMenu {
    position: relative;
    width: 220px;
}

.sortMenu .label {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    user-select: none;
}

.sortMenu .label .txt {
    display: flex;
    gap: 15px;
    align-items: center;
    font-size: 15px;
}

.sortMenu .label .btn {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: var(--w1);
    transition: ease 0.4s;
}


.sortMenu.open .label .btn {
    transform: rotate(-180deg);
}

.sortMenu .drop {
    position: absolute;
    background: rgb(19, 19, 19);
    width: 100%;
    top: 55px;
    border-radius: 11px;
    padding: 10px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
    z-index: 99;
}

.sortMenu.open .drop {
    opacity: 1;
    pointer-events: all;
}


.sortMenu .drop ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.sortMenu .drop ul li {
    border-radius: 9px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px 10px;
    text-wrap: nowrap;
    cursor: pointer;
    transition: ease 0.4s;
    font-size: 15px;
}

.sortMenu .drop ul li.active {
    background: rgba(255, 255, 255, 0.05);
}
.sortMenu .drop ul li:hover {
    background: rgba(255, 255, 255, 0.03);
}




.commandsList {
    padding: 70px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.command {
    padding: 20px 30px;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.03);
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
}
.command .nameContainer {
    display: flex;
    align-items: center;
}

.command .name {
    font-size: 18px;
    font-weight: 800;
    color: var(--primary);
}

.command .description {
    font-size: 16px;
    font-weight: 600;
    opacity: 0.7;
    padding-top: 10px;
}

.command .options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 20px;
    gap: 5px;
}

.command .options .nmContainer {
    width: max-content;
    background: rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    gap: 3px;
    align-items: center;
    opacity: 0.9;
}

.command .options.required .nmContainer {
    background: rgba(0, 0, 0, 0.2);
}

.command .options h1 {
    font-size: 15px;
    font-weight: 600;
    width: max-content;
}

.command .options .required {
    top: 0px;
    right: 0px;
    font-size: 7px;
}

.command .options .dsc {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.7;
    padding-left: 10px;
}


.command .option {
    position: relative;
}
.command .option .drop {
    position: absolute;
    width: 300px;
    max-width: 300px;
    background: #c4c4c4;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    z-index: 99;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 10px);
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.1s;
    border-radius: 10px;
    padding: 10px 20px;
}




.command .option .drop .dsc {
    color: var(--w1);
    opacity: 1;
}

.command .option:hover .drop {
    opacity: 1;
    pointer-events: all;
}
@media screen and (max-width: 500px) {
    .commandsList {
        padding: 60px 25px;
    }
}