
.dashboardContent {
    position: fixed;
    top: 70px;
    width: calc(100% - 70px - 300px);
    height: calc(100vh - 70px);
    overflow-x: auto;
    left: calc(70px + 300px);
    padding: 30px;
    z-index: 20;
}


.dashboardContent::-webkit-scrollbar {
    background: transparent;
    width: 8px;
}

.dashboardContent::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
}




.serversSelect {
    position: fixed;
    height: calc(100vh - 70px);
    top: 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    padding-bottom: 10px;
    overflow: auto;
    direction: rtl;
}


.serversSelect .sep {
    height: 2px;
    width: 80%;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 100px;
}

.serversSelect .srvr {
    direction:ltr;
    position: relative;
    display: flex;
    justify-content: center;
}

.serversSelect .srvr::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 0px;
    border-radius: 0px 100px 100px 0px;
    background: var(--w2);
    z-index: 99;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: ease 0.3s;
}

.serversSelect .srvr.active::before {
    opacity: 1;
    height: 20px;
}


.serversSelect .srvr a {
    text-decoration: none;
}

.serversSelect .srvr .icon {
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
    transition-delay:0s;
}

.serversSelect .srvr:hover .icon, .serversSelect .srvr.active .icon {
    border-radius: 35%;
}

.serversSelect .srvr .icon img {
    width: 101%;
    height: 101%;
}

.serversSelect .srvr .icon span {
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}


.serversSelect::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

.serversSelect::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
}





.serverMenu {
    position: fixed;
    top: 70px;
    width: calc(100% - 70px);
    height: calc(100vh - 70px);
    left: 70px;
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.serverMenu .top {
    display: flex;
    align-items: center;
    gap: 20px;
}

.serverMenu .top .name {
    font-weight: 800;
    font-size: 20px;
    width: 170px;
    white-space: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-wrap: nowrap;
}


.serverMenu.skeleton .top .name {
    width: 170px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 10px;
}


.serverMenu .icon {
    width: 60px ;
    height: 60px;
    border-radius: 35%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.02);
    display: flex;
    justify-content: center;
    align-items: center;
}
.serverMenu .icon img {
    width: 101%;
    height: 101%;
}

.serverMenu .icon span {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}


.srvMnOp {
    width: 100%;
    padding: 20px 20px;
}

.serverMenu ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    gap: 14px;
}

.serverMenu ul a {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 5px 20px;
    border-radius: 14px;
    transition: ease 0.3s;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}


.serverMenu.skeleton ul a {
    background:rgba(255, 255, 255, 0.02);
}

.serverMenu ul a svg {
    font-size: 18px;
}

.serverMenu ul a.active {
    background: var(--primary);
}

.serverMenu ul a:not(.active):hover {
    background: rgba(255, 255, 255, 0.02);
}





@media screen and (max-width: 900px) {
    .sideMenu {
        background: linear-gradient(150deg, #0f0f0f 0%, #000000 100%);
        z-index: 80;
        position: fixed;
        transform: translateX(-500px);
        transition: ease 0.3s;
    }
    .sideMenu.open {
        transform: translateX(0px);
    }

    

    .sideMenu.open ~ .dashboardContent {
        opacity: 0;
    }

    .dashboardContent {
        left: 0px;
        width: 100%;
        z-index: 20;
        opacity: 1;
        transition: ease 0.2s
    }
}

.guildSettingsMain {
    padding: 20px 0px;
    width: 100%;
}

@media screen and (max-width: 500px) { 
    .serverMenu {
        width: calc(100vw - 70px);
    }
    .guildSettingsMain {
        padding: 0px;
    }
}





.saveBtnSet {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);    
    padding: 5px 20px;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: min-content;
    margin-top: 5px;
    transition: ease 0.3s;
}

.saveBtnSet.disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.7;
}

.saveBtnSet svg.ldgSet {
    animation: spin 1s ease infinite;
}