.serverApp {
    display: flex;
    flex-direction: column;
    padding: 0px 10%;
    padding-top: 100px;
}

.serverApp .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}


.serverApp .top .a {
    display: flex;
    gap: 40px;
}

.serverApp .top .nfs {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.serverApp .top .icon {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 35%;
    overflow: hidden;
    width: 120px;
    height: 120px;
}

.serverApp .top .icon img {
    width: 101%;
    height: 101%;
}


.serverApp .top .name {
    font-weight: 900;
    font-size: 40px;
    color: var(--w2);
    opacity: 0.9;
}


.serverApp .top .stars {
    display: flex;
    font-size: 30px;
    gap: 0px;
}

.serverApp .top .voteCount {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    color: var(--w2);
    opacity: 0.8;
    margin-bottom: -2.5px;
}

.serverApp .top .stars svg {
    fill: var(--primary);
    cursor: pointer;
}

.serverApp .top .stars.empty svg {
    fill: rgba(255, 255, 255, 0.1);
}

.serverApp .top .vote {
    display: flex;
    align-items: center;
    gap: 10px;
}


.serverApp .btns {
    display: flex;
    gap: 10px;
}

.serverApp .btns .btn {
    padding: 5px 20px;
    border-radius: 10px;
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}


.serverApp .btns .btn:first-child {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.06);
}


.serverApp .btns .shareDrop {
    position: relative;
    user-select: none;
}

.serverApp .btns .shareDrop .btn {
    font-size: 20px;
}

.serverApp .btns .shareDrop .drp {
    position: absolute;
    background: var(--back);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 10px 10px;
    right: 0px;
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    top: 50px;
    gap: 10px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
}

.serverApp .btns .shareDrop.open .drp {
    opacity: 1;
    pointer-events: all;
}

.serverApp .btns .shareDrop .drp .scbtn {
    padding: 5px 20px;
    height: min-content;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: ease 0.3s;
    opacity: 0.9;
    cursor: pointer;
}

.serverApp .btns .shareDrop .drp .scbtn svg {
    font-size: 22px;
}

.serverApp .btns .shareDrop .drp .scbtn.sent {
    display: flex;
    opacity: 0.5;
    pointer-events: none;
}

.serverApp .btns .shareDrop .drp .scbtn:hover {
    background: rgba(255, 255, 255, 0.05);
}

.serverApp .btns .shareDrop .drp .rptForm {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.serverApp .btns .shareDrop .drp .rptForm textarea {
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: var(--w2);
    background: none;
    height: 100px;
    resize: none;
    margin-top: 5px;
}

.serverApp .btns .shareDrop .drp .rptForm .sendRpt {
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 13px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    margin-bottom: 5px;
    cursor: pointer;
}


.serverApp .btns .shareDrop .drp .rptForm .sendRpt.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
}

@media screen and (max-width: 700px) {
    .serverApp {
        padding: 0px 25px;
        padding-top: 70px;
    }
    .serverApp .top .a {
        gap: 20px;
    }


    .serverApp .top .nfs {
        gap: 0px;
    }


    .serverApp .top .icon { 
        width: 100px;
        height: 100px;
    }

    .serverApp .top .name {
        font-size: 30px;
    }

    .serverApp .btns {
        width: 100%;
    }
    .serverApp .btns .btn  {
        width: 100%;
        justify-content: center;
    }

}




@media screen and (max-width: 500px) {
    .serverApp .top .icon { 
        width: 80px;
        height: 80px;
    }
}







.serverApp .description {
    padding: 50px;
    font-size: 17px;
    font-weight: 500;
}

.serverApp .cats {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-top: 30px;
}

.serverApp .cats .mCt {
    padding: 6px 17px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    opacity: 0.9;
    text-align: center;
}

.reviews {
    display: flex;
    flex-direction: column;
    padding: 50px 0px;
}

.reviews h1.title {
    font-size: 30px;
    font-weight: 900;
    color: var(--w2);
}


.rvwStats {
    display: flex;
    justify-content: space-between;
}


.rvwStats .lft {
    display: flex;
    flex-direction: column;
}

.rvwStats .lft .vtsL {
    font-size: 55px;
    font-weight: 900;
    color: var(--primary);
    height: min-content;
    display: flex;
    flex-direction: column;
}

.rvwStats .lft h3 {
    font-size: 20px;
    font-weight: 800;
    color: var(--w2);
    text-transform: uppercase;
    margin-top: -15px;
    opacity: 0.7;
}

.rvwStats .stls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: max-content;
    width: 90%;
    max-width: 500px;
}

.rvwStats .stls .stt {
    display: flex;
    align-items: center;
    gap: 20px;
}

.rvwStats .stls .stt h4 {
    font-size: 20px;
    font-weight: 900;
    color: var(--w2);
    width: 50px;
    display: flex;
    justify-content: flex-end;
    opacity: 0.8;
}

.rvwStats .rgt {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.rvwStats .stls .bar {
    width: 90%;
    max-width: 500px;
    background: rgba(255, 255, 255, 0.05);
    height: 5px;
    outline: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 100px;
}

.rvwStats .stls .bar .fill {
    height: 100%;
    width: 0%;
    background: var(--primary);
    border-radius: 100px;
    transition: ease 0.3s;
}




.rateForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
    position: relative;
}

.rateForm.disabled {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
}

.rateForm .adv {
    position: absolute;
    font-size: 30px;
    font-weight: 900;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rateForm .errorMessage {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: flex-end;
}


.rateForm.disabled .rateStars, .rateForm.disabled textarea, .rateForm.disabled .sendRate {
    opacity: 0.3 !important;
}

.rateStars {
    display: flex;
    font-size: 35px;
    gap: 0px;
    cursor: pointer;
}


.rateStars svg.empty {
    fill: rgba(255, 255, 255, 0.07);
}
.rateStars svg.full {
    fill: var(--primary);
}


.rateForm textarea {
    background: transparent;
    outline: none;
    border: none;
    height: 110px !important;
    width: 100% !important;
    max-width: 500px !important;
    font-size: 16px;
    font-weight: 600;
    resize: none;
    padding: 0px 0px;
}


.rateForm .sendRate {
    background: var(--back);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 7px 50px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    width: 180px;
}

.rateForm .sendRate.dlt {
    padding: 7px 20px;
    width: max-content;
}

.rateForm .sendRate.disabled {
    cursor: not-allowed
}
.rateForm .sendRate svg {
    font-size: 22px;
}

.rateForm .sendRate svg:not(.trsh) {
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg)
    }
}


@media screen and (max-width: 650px) {

    .rvwStats {
        flex-direction: column;
    }
    .rvwStats .rgt {
        justify-content: center;
        width: 100%;
    }
    .rvwStats .rgt .stls {
        width: 100%;
        max-width: none;
    }
    .rvwStats .vtsL {
        flex-direction: row !important;
        align-items: center;
        display: flex;
        gap: 10px;
    }
    
}



.fdbList {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 50px 0px;
}


.fdb {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    position: relative;
}


.fdb .rptPanel {
    position: relative;
}


.fdb .rptPanel .label {
    background: var(--back);
    border: 2px solid rgba(255, 255, 255, 0.06);
    padding: 8px 7px;
    font-size: 17px;
    border-radius: 10px;
    width: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.fdb .rptPanel .drp {
    position: absolute;
    background: var(--back);
    padding: 10px 10px;
    right: 0px;
    width: 230px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    top: 50px;
    gap: 10px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
}

.fdb .rptPanel .drp .report {
    padding: 5px 20px;
    height: min-content;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: ease 0.3s;
    opacity: 0.9;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.fdb .rptPanel .drp .report svg {
    font-size: 22px;
}

.fdb .rptPanel .drp .report:hover {
    background: rgba(255, 255, 255, 0.05);
}


.fdb .rptPanel.open .drp {
    opacity: 1;
    pointer-events: all;
}



.fdb .fTp {
    display: flex;
    align-items: center;
    gap: 15px;
}


.fdb .fTp .icon {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 40%;
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.fdb .fTp .icon img {
    width: 101%;
    height: 101%;
}

.fdb .fTp h1 {
    font-weight: 700;
    font-size: 20px;
}

.fdb .content {
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
}

.fdb .date {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    opacity: 0.8;
    gap: 5px;
}

.fdb .fdbStarsRate {
    font-size: 22px;
    margin-top: 10px;
}


.fdb .fdbStarsRate svg.full {
    fill: rgba(255, 255, 255, 0.6);
}

.fdb .fdbStarsRate svg.empty {
    fill: rgba(255, 255, 255, 0.07);
}
