.loadingApp.bumps {
    margin-top: 150px;
}


.searchApp {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    gap: 15px;
    flex-wrap: wrap;
}


.searchBar {
    background: rgba(255, 255, 255, 0.05);
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
}

.searchBar input {
    width: 100%;
    padding: 5px 20px;
    border: none;
    outline: none;
    background: none;
}

.searchBar .srcBtn {
    background: var(--w);
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
    border-radius: 9px;
    cursor: pointer;
}


.searchBar .srcBtn svg {
    fill: var(--g1);
}



.sortMenu {
    position: relative;
    width: 220px;
}

.sortMenu .label {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    user-select: none;
}

.sortMenu .label .txt {
    display: flex;
    gap: 15px;
    align-items: center;
    font-size: 15px;
}

.sortMenu .label .btn {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: var(--w1);
    transition: ease 0.4s;
}


.sortMenu.open .label .btn {
    transform: rotate(-180deg);
}

.sortMenu .drop {
    position: absolute;
    background: rgb(19, 19, 19);
    width: 100%;
    top: 55px;
    border-radius: 11px;
    padding: 10px;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.2s;
    z-index: 99;
}

.sortMenu.open .drop {
    opacity: 1;
    pointer-events: all;
}


.sortMenu .drop ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.sortMenu .drop ul li {
    border-radius: 9px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px 10px;
    text-wrap: nowrap;
    cursor: pointer;
    transition: ease 0.4s;
    font-size: 15px;
}

.sortMenu .drop ul li.active {
    background: rgba(255, 255, 255, 0.05);
}
.sortMenu .drop ul li:hover {
    background: rgba(255, 255, 255, 0.03);
}


.badges {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
}

.badges h1 {
    font-size: 30px;
    font-weight: 900;
}

.badgesList {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}


.badgesList .b {
    display: flex;
    align-items: center;
    gap: 5px;
    background: rgb(19, 19, 19);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 3px 10px;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.badgesList .b.selected {
    background: var(--primary);
    border: 2px solid rgb(179, 103, 224);
}

.badgesList .b div {
    display: flex;
    align-items: center;
}

.badgesList .b .hided {
    opacity: 0;
}



.badgesList .b.selected .lbl {
    transform: translateX(-60%);
}

.badgesList .b .lbl {
    font-size: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: max-content;
    transition: ease 0.2s;
}


.badgesList .b.selected .icon {
    opacity: 1;
}

.badgesList .b .icon {
    font-size: 18px;
    opacity: 0;
}




.usersList {
    padding: 70px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}


.user {
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 25px;
    padding: 10px 30px;
    max-width: 900px;
}

.user .icon {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 35%;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 0px 20px;
}

.user .icon img {
    width: 101%;
    height: 101%;
}

.user .txts {
    display: flex;
    align-content: center;
    gap: 15px;
    width: calc(100% - 60px - 80px);
    justify-content: space-between;
    flex-wrap: wrap;
}

.user .name {
    font-size: 25px;
    font-weight: 900;
    color: var(--primary);
    display: flex;
    align-items: center;
}

.user .votesCount {
    font-size: 20px;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    gap: 5px;
    align-items: center;
}

.user .votesCount span {
    font-size: 30px;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.7);
}



.user .rank {
    font-size: 30px;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.7);
}

 @media screen and (max-width: 500px) {
    .badges {
        padding-left: 25px;
        padding-right: 25px;
    }
    .serversList {
        padding: 60px 25px;
    }
    .searchApp {
        padding-top: 80px;
    }
 }