.loadingApp.bumps {
    margin-top: 150px;
}


.searchApp {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    gap: 15px;
    flex-wrap: wrap;
}


.searchBar {
    background: rgba(255, 255, 255, 0.05);
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
}

.searchBar input {
    width: 100%;
    padding: 5px 20px;
    border: none;
    outline: none;
    background: none;
}

.searchBar .srcBtn {
    background: var(--w);
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
    border-radius: 9px;
    cursor: pointer;
}


.searchBar .srcBtn svg {
    fill: var(--g1);
}

.codesApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}



.codesList {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0px; 
    gap: 50px 50px;
    flex-wrap: wrap;
    width: 80%;
}

.codesList .cdMapped {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    flex: calc(50% - 50px);
    min-width: 300px;
    background: rgba(255, 255, 255, 0.005);
    padding: 13px 13px;
    border-radius: 20px;
    align-self: stretch;
    max-width: calc(16 / 5 * 220px);
}


.cdMapped .image {
    display: flex;
    justify-content: center;
}
.cdMapped .image .img {
    height: 220px;
    width: 100%;
    max-width: calc(16 / 5 * 220px);
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.cdMapped .image .img img {
    width: 101%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.cdMapped .infs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.cdMapped .infs h1 {
    font-size: 25px;
    font-weight: 900;
    margin-top: 10px;
    padding: 0px 10px;
    color: var(--primary);
}

.cdMapped .infs h1::first-letter {
    text-transform: uppercase;
}

.cdMapped .infs p {
    padding: 0px 10px;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .6px;
    opacity: .9;
    line-break: anywhere;
    max-height: 300px;
    text-overflow:ellipsis;
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cdMapped .btns {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    right: 10px;
    bottom: 10px;
}

.cdMapped .btns a {
    font-size: 15px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 13px;
    padding: 7px 23px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: max-content;
    color: rgba(255, 255, 255, 0.6);
}
.cdMapped .btns .views {
    display: flex;
    align-items: center;
    font-weight: 900;
    color: var(--primary);
    gap: 8px;
    font-size: 17px;
    background: rgba(255, 255, 255, 0.01);
    border-radius: 13px;
    padding: 7px 23px;
}
.cdMapped .btns .views svg {
    font-size: 15px;
    display: flex;
    align-items: center;
    fill: rgba(255, 255, 255, 0.6);
}


.cdMapped .btns a svg {
    display: flex;
    align-items: center;
    fill: rgba(255, 255, 255, 0.6);
}


@media screen and (max-width: 830px) {
    .codesList .cdMapped {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .codesList {
        width: 100%;
    }
    .codesList .cdMapped {
        width: calc(100% - 40px);
        flex-direction: column;
    }
    
.cdMapped .infs h1 {
    font-size: 23px;
}


.cdMapped .infs p {
    font-size: 15px;
}

}