.serversAdminSets {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
    width: 100%;
}

.serversAdminSets h1.bgTilt {
    font-size: 25px;
    font-weight: 800;
    margin: 40px 0px 20px;
    display: flex;
    justify-content: flex-start;
}

.serversAdminSets h1.bgTilt.scd {
    margin-top: 70px;
}

.serversAdminSets .srcBr {
    display: flex;
    align-items: center;
    background: rgba(255, 255 ,255, 0.05);
    width: 100%;
    max-width: 600px;
    height: 40px;
    border-radius: 13px;
    padding-right: 5px;
}
.serversAdminSets .srcBr input {
    width: 100%;
    height: 100%;
    border: none; 
    outline: none;
    color: var(--w2);
    background: none;
    padding: 0px 15px;
}


.serversAdminSets .srcBr .srcBnt {
    background: rgba(255, 255 ,255, 0.7);
    height: calc(100% - 10px);
    width: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.serversAdminSets .srcBr .srcBnt svg {
    fill: var(--g3);
}



.srvLsts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 25px;
    width: 90%;
    padding: 20px;
    margin-top: 50px;
    max-height: 325px;
    min-height: 325px;
    overflow-y: auto;
}


.srvLsts .srvMapped {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 15px;
    position: relative;
}
.srvLsts .srvMapped .infs {
    display: flex;
    align-items: center;
    gap: 20px;
}

.srvLsts .srvMapped .infs h1 {
    font-size: 19px;
    font-weight: 900;
    color: var(--w2);
    cursor: pointer;
}

.srvLsts .srvMapped .icon {
    background: rgba(0, 0, 0, 0.2);
    width: 45px;
    height: 45px;
    border-radius: 35%;
    overflow: hidden;
}

.srvLsts .srvMapped .icon img {
    width: 101%;
    height: 101%;
}



.srvLsts .srvMapped .lftBtn {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    user-select: none;
}


.srvLsts .srvMapped .optsBtnSrv {
    position: relative;
    z-index: 99;
}

.srvLsts .srvMapped .optsBtnSrv .obtnDrop {
    position: absolute;
    right: calc(100% + 10px);
    top: 0px;
    background: #111;
    padding: 8px 8px;
    z-index: 99;
    border-radius: 10px;
    width: 200px;
    opacity: 0;
    transition: ease 0.2s;
    pointer-events: none;
    user-select: none;
}
.srvLsts .srvMapped .optsBtnSrv.open .obtnDrop {
    opacity: 1;
    pointer-events: all;
}

.srvLsts .srvMapped .optsBtnSrv .optsDrp {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.srvLsts .srvMapped .optsBtnSrv .optsDrp li {
    list-style: none;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    transition: ease 0.2s;
}
.srvLsts .srvMapped .optsBtnSrv .optsDrp li:hover {
    background: rgba(255, 255 ,255, 0.02);
}
/*
.srvLsts .srvMapped .optsBtnSrv .optsDrp li:last-child {
    background: #ff4242;
    color: #111;
}
*/

.srvLsts .srvMapped .optsBtnSrv .obtnSrv {
    background: rgba(255, 255 ,255, 0.02);
    border: 2px solid rgba(255, 255 ,255, 0.06);
    width: 30px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: ease 0.2s;
}

.srvLsts .srvMapped .optsBtnSrv.open .obtnSrv {
    background: rgba(255, 255 ,255, 0.04);
}

/* scrollbar */

.srvLsts::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

.srvLsts::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
}





@media screen and (max-width: 550px) {
    .serversAdminSets {
        width: 100%;
    }
    .srvLsts .srvMapped {
        padding: 15px 0px;
    }
    .srvLsts .srvMapped .lftBtn {
        right: 0px;
    }
    .srvLsts {
        width: 100%;
        padding-left: 0px;
    }
}