.userSettings {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px 0px;
}

.counter h1 {
    font-size: 25px;
    font-weight: 900;
    color: var(--w2);
    margin-top: -25px;
}

.counter h3 {
    font-size: 50px;
    font-weight: 900;
    color: var(--primary);
}