.codeEditor {
    padding: 30px;
}

.codeEditor h1 {
    font-size: 28px;
    font-weight: 900;
}
.codeEditor .inptsFlex {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}


.codeEditor .editorInp {
    display: flex;
    width: 100%;
}




.codeEditor .editorInp input {
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    padding: 0px 15px;
}

.codeEditor .editorInp textarea {
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    padding: 15px 15px;
    resize: none;
}


.codeEditor .editorInp textarea#description {
    height: 80px;
}


.codeEditor .editorInp textarea#code {
    height: 300px;
}


.btnsList {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.codeEditor .editorInp .btn {
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    padding: 6px 20px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    transition: ease 0.3s;
}


.codeEditor .editorInp .btn:nth-child(2) {
    background: #ff3535ad;
    color: var(--g3);
}

.codeEditor .editorInp .btn#disabled {
    opacity: 0.7;
    pointer-events: none;
    user-select: none;
}

.codeEditor .editorInp .btn svg {
    display: flex;
    align-items: center;
    animation: spin 1s ease infinite;
}


.codesList {
    padding: 30px;
}

.codesList h1 {
    font-size: 28px;
    font-weight: 900;
}

.codeSlct {
    width: 100%;
}

.codeMapped {
    padding: 30px;
}

.codeMapped .tps {
    display: flex;
    align-items: center;
    gap: 10px;
}


.codeMapped .tps h1 {
    font-size: 25px;
    font-weight: 800;
}


.codeMapped .tps p {
    font-size: 17px;
    font-weight: 600;
    opacity: 0.5;
}


.codeMapped a {
    margin-top: 5px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(255, 255, 255, 0.05);
    padding: 6px 20px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    transition: ease 0.3s;
    width: max-content;
    text-decoration: none;
}